export const environment = {
  baseApi: 'https://apps-api.staging.micetribe.com/',
  production: false,
  rootApi: `https://apps-api.staging.micetribe.com/api/`,
  authApi: `https://apps-api.staging.micetribe.com/api/auth/`,
  planApi: `https://apps-api.staging.micetribe.com/api/organizations/`,
  libraryApi: `https://apps-api.staging.micetribe.com/library/`,
  genericApi: `https://apps-api.staging.micetribe.com/api/generic/`,
  crewApi: `https://apps-api.staging.micetribe.com/api/crews/`,
  stripeKey: 'pk_test_47Z7iMDWR236BIUktKpxkWXG',
  googleClientID:
    '671059974212-nb5si672ktqkj2poscjj9del2i58sgi5.apps.googleusercontent.com',
  linkedinClientID: '772jwev8ar9ai0',
  privacyPolicy: 'https://micetribe.com/privacy-policy/',
  termsAndConditions: 'https://micetribe.com/terms-conditions/',
  testApi: `test.com/`,
  appUrl: `https://apps.staging.micetribe.com/`,
  s3_docs_root: `https://staging-storage-v4.s3.eu-west-3.amazonaws.com/documents/`,
  s3_files_root: `https://staging-storage-v4.s3.eu-west-3.amazonaws.com/`,
  mapboxToken:
    'pk.eyJ1IjoiY29udGFjdGxlc3MiLCJhIjoiY2xobXpwMjJrMWFlcDNlb3h5aGh4bjJoOSJ9.ulKz7o1wPpEBk48Tz33BYw',
  // tinyMCE_API_KEY: 'wkt9uz7idfufuxan0fskj3fmtc1gcc3326c0oroypcg3mqk2',
  tinyMCE_API_KEY: 'rq9lnthcyfkxy5t5fj1krg7crd4ld1tqv53k4o9dfuhckq6f',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDORqT-hBGoHAhZNtQ0ZxGOsXZOxaijcRk',
};
