<nav
  class="bg-white py-2 px-10 fixed z-20 top-0 h-12 shadow-[0_2px_2px_0_#00000014]"
>
  <div class="flex gap-6 m-auto items-center h-full">
    <div
      class="domain__dropdown__toggler h-full flex items-center group cursor-pointer gap-3"
      style="margin-right: -0.5rem"
    >
      <img
        src="assets/logos/logo.svg"
        alt="MICEtribe logo"
        (click)="routeHome()"
      />
      <div class="hover:bg-gray-100 py-1 px-2 rounded-lg relative">
        <app-dropdown 
          [heightInPX]="100" 
          [widthInPX]="'210px'" 
          [dropdownOptions]="domainsDropdownOptions" 
          [hiddenOption]="(selectedDomain$ | async) || undefined"
          (optionClicked)="onDomainOptionClicked($event)">
          <img
            [src]="'assets/logos/' + (selectedDomain$ | async) + '-logo.svg'"
            [alt]="(selectedDomain$ | async) + ' app'"
          />
        </app-dropdown>
        <!-- Domain Dropdown -->
      </div>
    </div>
    @if (showOrganizationSection) {
      <span class="border-gray-200 border-l h-full hidden md:inline"></span>
      <app-dropdown [heightInPX]="147" [widthInPX]="'300px'" [dropdownOptions]="organizationDropdownOptions" (optionClicked)="onOrganizationOptionClicked($event)">
        <div
          [ngClass]="{
            'hover:bg-gray-100 cursor-pointer': !disableOrganizationChange
          }"
          class="organizations__dropdown__toggler relative hidden md:flex gap-1.5 items-center bg-transparent rounded-lg pr-3 h-full select-none w-full"
          style="max-width: 300px"
        >
          <div
            class="flex-shrink-0 rounded-lg h-full flex justify-center items-center p-1 bg-transparent w-8 max-w-8"
            [ngClass]="{
              'border border-gray-200': !organizationLogo?.cropped_src
            }"
          >
            @if (organizationLogo?.cropped_src) {
            <img
              [src]="organizationLogo?.cropped_src"
              [alt]="organizationName || 'Organization name'"
              class="w-full h-full object-contain rounded-lg"
            />
            } @else {
            <svg class="w-5 h-5 fill-gray-500" viewBox="0 0 18 18">
              <path
                d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"
              />
            </svg>
            }
          </div>
          <span
            class="text-gray-700 text-sm inline-block font-medium text-ellipsis overflow"
          >
            {{ organizationName || "Organization name" }}
          </span>
          <!-- organizations dropdown -->
        </div>
      </app-dropdown>
    }
    <div class="flex relative items-center ms-auto gap-4 h-full">
      <div class="flex items-center gap-3">
        @if (showUpgradeButton) {
        <!-- Upgrade button -->
        <button
          type="button"
          tabindex="-1"
          ngxTippy="Coming soon"
          class="hidden xl:flex ms-0 xl:ms-auto bg-yellow-300 hover:bg-[#F6C301] rounded-xl px-3 py-2 text-xs font-medium leading-6 text-[#212121] items-center gap-2 h-8"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.22415 6.28391L6.53197 7.12934C6.02684 7.3817 5.6101 7.79811 5.35754 8.30284L4.51144 9.99369C4.38516 10.2334 4.0442 10.2334 3.91792 9.99369L3.07182 8.30284C2.81926 7.79811 2.40253 7.3817 1.8974 7.12934L0.179953 6.28391C-0.0599842 6.15773 -0.0599842 5.81703 0.179953 5.69085L1.88477 4.85804C2.3899 4.60568 2.80663 4.18927 3.0592 3.68454L3.90529 1.99369C4.03157 1.75394 4.37253 1.75394 4.49882 1.99369L5.34491 3.68454C5.59747 4.18927 6.01421 4.60568 6.51934 4.85804L8.21152 5.70347C8.46409 5.81704 8.46409 6.17035 8.22415 6.28391ZM11.9242 10.0694L11.1918 9.70347C10.9771 9.58991 10.8003 9.41325 10.6867 9.19874L10.3204 8.46688C10.2699 8.36593 10.1184 8.36593 10.0679 8.46688L9.70166 9.19874C9.588 9.41325 9.41121 9.58991 9.19653 9.70347L8.46409 10.0694C8.36306 10.1199 8.36306 10.2713 8.46409 10.3218L9.19653 10.6877C9.41121 10.8013 9.588 10.9779 9.70166 11.1924L10.0679 11.9243C10.1184 12.0252 10.2699 12.0252 10.3204 11.9243L10.6867 11.1924C10.8003 10.9779 10.9771 10.8013 11.1918 10.6877L11.9242 10.3218C12.0253 10.2587 12.0253 10.1199 11.9242 10.0694ZM11.9242 1.67823L11.1918 1.3123C10.9771 1.19874 10.8003 1.02208 10.6867 0.807571L10.3204 0.0757098C10.2699 -0.0252366 10.1184 -0.0252366 10.0679 0.0757098L9.70166 0.807571C9.588 1.02208 9.41121 1.19874 9.19653 1.3123L8.46409 1.67823C8.36306 1.72871 8.36306 1.88013 8.46409 1.9306L9.19653 2.29653C9.41121 2.41009 9.588 2.58675 9.70166 2.80126L10.0679 3.53312C10.1184 3.63407 10.2699 3.63407 10.3204 3.53312L10.6867 2.80126C10.8003 2.58675 10.9771 2.41009 11.1918 2.29653L11.9242 1.9306C12.0253 1.86751 12.0253 1.72871 11.9242 1.67823Z"
              fill="#1F2A37"
            />
          </svg>
          Upgrade to PRO
        </button>
        <!-- /Upgrade button -->
      }
      @if (showWallet) {
        <!-- Wallet -->
        <button
          class="hidden peer xl:flex border border-green-300 hover:bg-green-50 active:bg-green-100 rounded-xl items-center px-3 py-2 gap-2 h-8 w-[75px] justify-center"
          [ngClass]="{
          'border-orange-400': walletAmount <= 50 && walletAmount !== 0,
          'hover:bg-yellow-50': walletAmount <= 50 && walletAmount !== 0,
          'active:bg-yellow-100': walletAmount <= 50 && walletAmount !== 0,
          'border-red-400': walletAmount === 0,
          'hover:bg-red-50': walletAmount === 0,
          'active:bg-red-200': walletAmount === 0,
        }"
        >
          <svg
            class="w-3.5 fill-green-500"
            [ngClass]="{
              'fill-orange-500': walletAmount <= 50,
              'fill-red-500': walletAmount === 0
            }"
            viewBox="0 0 20 20"
          >
            <path
              d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z"
            />
            <path
              d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z"
            />
          </svg>
          <span
            class="text-green-700 text-xs font-medium leading-[18px]"
            [ngClass]="{
              'text-orange-700': walletAmount <= 50,
              'text-red-700': walletAmount === 0
            }"
          >
            ${{ amount | number : "1.0-0"
            }}{{
              walletAmount >= 1000 && walletAmount < 1000000
                ? "K"
                : walletAmount >= 1000000
                ? "M"
                : ""
            }}
          </span>
        </button>
        <div
          class="hidden left-32 peer-hover:flex flex-col gap-1 py-2 px-4 bg-white border border-gray-200 rounded-md absolute top-11 shadow-sm"
        >
          <span class="text-sm leading-4 font-semibold text-gray-900">
            Current balance
          </span>
          <span
            class="text-sm leading-4 font-normal text-green-700"
            [ngClass]="{
              'text-orange-700': walletAmount <= 50,
              'text-red-700': walletAmount === 0
            }"
          >
            ${{ walletAmount | number : "1.0-5" }}
          </span>
        </div>
        <!-- /Wallet -->
      }
      </div>
      <div class="flex items-center gap-6">
        <div class="flex gap-6">
          <button class="hidden md:inline-block" ngxTippy="Coming soon">
            <svg
              class="w-4 h-4 fill-gray-500 hover:fill-gray-700"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"
              />
            </svg>
          </button>
          <button ngxTippy="Coming soon">
            <svg
              class="w-4 h-4 fill-gray-500 hover:fill-gray-700"
              viewBox="0 0 14 20"
            >
              <path
                d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z"
              />
            </svg>
          </button>
        </div>
        <!-- User Dropdown -->
        <app-dropdown [heightInPX]="100" [widthInPX]="'fit-content'" [minWidthInPX]="'210px'" [dropdownOptions]="userDropdownOptions" (optionClicked)="onUserOptionClicked($event)" (dropdownClosed)="toggleUserDropdown()">
          <button
            class="relative user__dropdown__toggler"
            (click)="toggleUserDropdown()"
          >
            <!-- If there's a profile pic -->
            @if (user?.avatar?.cropped_src) {
            <img
              [src]="user?.avatar?.cropped_src"
              [alt]="'Profile picture of ' + user?.full_name"
              class="rounded-md w-4 h-4 object-cover shadow-sm outline outline-1 hover:outline-blue-100"
              [ngClass]="{
                'outline-blue-500': userDropdown,
                'outline-transparent': !userDropdown
              }"
            />
            } @else {
            <span
              class="rounded-md bg-gray-100 font-medium text-xs leading-3 shadow-sm w-4 h-4 flex items-center justify-center outline outline-1"
              [ngClass]="{
                'outline-blue-500': userDropdown,
                'outline-transparent hover:outline-blue-100': !userDropdown
              }"
            >
              {{ fullNameAcronym }}
            </span>
          }
          </button>
        </app-dropdown>
      </div>
    </div>
  </div>
</nav>

<modal
  [enableBackdropClose]="false"
  [width]="537"
  [id]="'switch-organization-modal'"
  header="Switch organization"
  (onCloseModal)="toggleSwitchOrganizationModal()"
>
  <div
    slot="body"
    class="p-5 flex flex-col justify-center items-center gap-4 font-normal text-base leading-6 text-center text-gray-500"
  >
    <div class="relative flex gap-3 w-full">
      <div
        class="absolute inset-y-0 start-0 flex items-center ps-3 cursor-pointer"
      >
        <svg
          class="w-4 h-4 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        (input)="handleSearch($event)"
        type="search"
        id="search"
        class="w-full lg:w-[500px] block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        placeholder="Search for an organization"
        required
      />
    </div>
    <ul class="bg-white workspaces__dropdown overflow-x-auto w-full">
      <div
        class="workspaces__list overflow-auto flex flex-col gap-3"
        style="max-height: min(600px, 50vh)"
      >
        <li
          *ngFor="let organization of filteredOrganizations; let i = index"
          (click)="selectOrganization(i)"
          class="rounded-lg border shadow-sm p-4 bg-gray-50 hover:bg-gray-100 active:bg-gray-100 border-b cursor-pointer border-gray-200"
        >
          <a class="h-full flex items-center gap-6">
            <div
              class="border border-gray-200 rounded-lg h-16 bg-white flex justify-center items-center"
              style="aspect-ratio: 1"
            >
              @if(organization.logo?.cropped_src) {
              <img
                [src]="organization.logo?.cropped_src"
                [alt]="organization.name"
                class="object-contain rounded-lg"
              />
              } @else {
              <svg class="w-5 h-5 fill-gray-500" viewBox="0 0 18 18">
                <path
                  d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"
                />
              </svg>
              }
            </div>

            <div class="flex flex-col h-full w-full gap-2 items-start">
              <div class="flex h-full items-center justify-between w-full">
                <span
                  class="text-base font-bold leading-5 text-gray-900 overflow-hidden text-ellipsis text-nowrap"
                  style="max-width: 300px"
                >
                  {{ organization.name }}
                </span>
                <div class="flex items-center flex-1 relative h-5">
                  @for(user of organization.users; track user.id;) {
                  @if(user.avatar?.cropped_src_xs) { @if ($index <= 3) {
                  <img
                    [src]="user.avatar?.cropped_src_xs"
                    [alt]="user.full_name"
                    class="w-4 h-4 aspect-square rounded-full object-cover absolute -z-1"
                    [style.right]="
                      organization.users!.length > 4
                        ? ($index + 1) * 12 + 'px'
                        : $index * 12 + 'px'
                    "
                    [style.zIndex]="-$index + 5"
                  />
                  } @else {
                  <div
                    class="bg-white border border-gray-200 text-blue-700 font-bold text-[9px] leading-[13px] w-4 h-4 aspect-square rounded-full absolute z-10 flex items-center justify-center"
                    [style.right]="0 + 'px'"
                  >
                    +{{ organization.users!.length - 4 }}
                  </div>
                  } } @else{
                  <span
                    class="rounded-full bg-gray-100 font-medium text-xs leading-3 shadow-sm w-6 h-6 flex items-center justify-center"
                  >
                    {{ user.full_name | titlecase }}
                  </span>
                  } }
                </div>
              </div>
              <div
                class="flex justify-between text-xs font-normal leading-4 text-gray-500 w-full h-full"
              >
                <div class="flex flex-col items-start">
                  <span>Live events: -</span>
                  <span>Upcoming events: -</span>
                </div>
                <div class="flex flex-col items-start">
                  <span>Last used: -</span>
                  <span>Created at: -</span>
                </div>
              </div>
            </div>
          </a>
        </li>
        @if(filteredOrganizations?.length === 0) {
        <li class="p-4 border-gray-200">Organization not found!</li>
        }
      </div>
    </ul>
  </div>

  <div slot="footer" class="flex justify-center gap-4 w-full p-6 pt-0">
    <button
      type="button"
      class="w-full py-2 px-3 primary-btn"
      (click)="navigateToCreateOrganization()"
    >
      + Create new organization
    </button>
  </div>
</modal>
