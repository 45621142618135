import { Routes } from '@angular/router';
import { MainGuard } from '../guards/main.guard';
import { EventSelectedGuard } from './guards/event-selected.guard';
import { CrewService } from './services/crew.service';

export default [
  {
    path: 'crew',
    loadComponent: () =>
      import('./crew.component').then((m) => m.CrewComponent),
    providers: [CrewService, EventSelectedGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/home-page/home-page.component').then(
            (m) => m.HomePageComponent
          ),
      },
      {
        path: 'event/:eventId',
        loadComponent: () =>
          import('./components/home-page/home-page.component').then(
            (m) => m.HomePageComponent
          ),
        children: []
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [MainGuard],
  },
] as Routes;
