import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CommonModule } from '@angular/common';
import { SafeDomPipe } from '@shared/pipes/safe-dom.pipe';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

/**
 * ModalComponent - A component that represents a modal window
 *
 * @selector modal
 * @animations modalAnimation
 */
@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [ModalService.animations],
  standalone: true,
  imports: [CommonModule, SafeDomPipe, ReactiveFormsModule],
})
export class ModalComponent implements OnInit, OnDestroy {
  /**
   * @property {string} header - The header of the modal window
   */
  @Input() header!: string;

  /**
   * @property {string} id - The identifier of the modal
   */
  @Input() id!: string;

  /**
   * @property {boolean} closeButtonEnabled - A flag indicating whether the close button is enabled
   * @default true
   */
  @Input() closeButtonEnabled: boolean = true;

  /**
   * @property {boolean} showHeaderDivider - A flag indicating whether a divider line should be shown under the header
   * @default true
   */
  @Input() showHeaderDivider: boolean = true;

  /**
   * @property {boolean} showFooterDivider - A flag indicating whether a divider line should be shown above the footer
   * @default true
   */
  @Input() showFooterDivider: boolean = true;

  /**
   * @property {number} width - The width of the modal window
   */
  @Input() width!: number;

  /**
   * @property {string} headerIcon - icon to be displayed in the header
   */
  @Input() headerIcon!: string;

  /**
   * @property {boolean} headerSwitch - toggle the switch for header
   */
  @Input() headerSwitch: boolean = false;

  /**
   * @property {number} height - The height of the modal window
   */
  @Input() height!: number;

  /**
   * @property {boolean} enableBackdropClose - A flag indicating whether the modal should be closed when the user clicks outside of it
   */
  @Input() enableBackdropClose: boolean = true;

  /**
   * @event onCloseModal - An event that gets emitted when the modal is closed
   */
  @Output() onCloseModal = new EventEmitter();

  /**
   * @property {FormControl} switchControl - The form control for the switch
   */
  @Input() switchControl!: FormControl;

  /**
   * @event onSwitchChange - An event that gets emitted when the switch is toggled
   */
  @Output() onSwitchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Constructor to inject necessary services and dependencies
   * @param _ModalService - A service for managing modals
   * @param renderer - Renderer2 for DOM manipulation
   * @param elementRef - Reference to the DOM element of the component
   */
  constructor(
    private _ModalService: ModalService,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {}

  /**
   * Lifecycle hook: Called once the component is initialized
   * Appends the modal component to the <body> tag to ensure it appears above other elements
   */
  ngOnInit(): void {
    // Append modal to the body tag
    this._renderer.appendChild(document.body, this._elementRef.nativeElement);
  }

  /**
   * Lifecycle hook: Called once the component is destroyed
   * Removes the modal component from the <body> tag to clean up the DOM
   */
  ngOnDestroy(): void {
    // Remove modal from the body tag
    this._renderer.removeChild(document.body, this._elementRef.nativeElement);
  }

  /**
   * Host listener for escape key press to close the modal
   * @param event - Keyboard event
   */
  @HostListener('document:keydown.escape', ['$event'])
  private onKeydownHandler() {
    if (this.isModalOpen()) this.closeModal();
  }

  /**
   * Check if the modal window is open
   *
   * @returns {boolean} - returns true if the modal window with the given id is open, otherwise false
   */
  isModalOpen(): boolean {
    return this._ModalService.isModalOpen(this.id);
  }

  /**
   * Emit an onCloseModal event
   */
  closeModal() {
    this.onCloseModal.emit();
  }

  /**
   * Handle the click event on the backdrop of the modal window
   * @returns void
   */
  public closeOnBackdropClick(): void {
    if (!this.enableBackdropClose) return;

    this.onCloseModal.emit();
  }

  /**
   * @method switchChange
   * @description Handle the click event on the switch
   * @param event - The event object
   * @returns void
   */
  public switchChange(event: Event) {
    this.onSwitchChange.emit((<HTMLInputElement>event.target).checked);
  }
}
