import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { register } from 'swiper/element/bundle';

import routes from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ApiInterceptor } from './interceptors/interceptor';
import { provideStore } from '@ngrx/store';
import { brandAndIdentityReducer, dateAndTimingsReducer, focusInputExistsReducer, formatAndLocationReducer, wizardCurrentStepReducer } from '@plan/events/components/event-wizard/store/reducers.store';

register();
const storeReducers = {
  focusInputExist: focusInputExistsReducer,
  brandAndIdentity: brandAndIdentityReducer,
  formatAndLocation: formatAndLocationReducer,
  dateAndTimings: dateAndTimingsReducer,
  wizardCurrentStep: wizardCurrentStepReducer
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([ApiInterceptor])),
    provideAnimations(),
    provideStore(storeReducers)
  ],
};
