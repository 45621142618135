import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { Observable, of, throwError, from, BehaviorSubject } from "rxjs";
import { switchMap, catchError, tap, filter, take } from "rxjs/operators";
import { inject } from "@angular/core";
import { AuthService } from "@auth/services/auth.service";
import { Router } from "@angular/router";
import { AlertService } from "@shared/services/alert.service";
import { Alert } from "@shared/enums/alert.types";
import { environment } from "src/environments/environment";

let isRefreshingToken = false;
const tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
  string | null
>(null);

export const ApiInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const alertService = inject(AlertService);

  const excludedLinks: string[] = [
    `${environment.authApi}sign-up`,
    `${environment.authApi}sign-in`,
    `${environment.authApi}email-exists`,
    `${environment.authApi}forgot-password`,
    `https://maps.googleapis.com/maps/api/timezone`
  ];

  /**
   * Adds the token to the header
   * @param req HttpRequest<any>
   * @param token string
   * @returns HttpRequest<any>
   */
  const addTokenToHeader = (
    req: HttpRequest<unknown>,
    token: string
  ): HttpRequest<any> => {
    return req.clone({
      headers: req.headers
        .set("Authorization", `Bearer ${token}`)
        .set("ngrok-skip-browser-warning", "true"),
    });
  };

  /**
   * Handles the refresh token process by calling the refresh token API.
   * Ensures only one request is made and waits for the result if a refresh is already in progress.
   * @returns Observable<string | null> - Emits the new token if refreshed, otherwise null.
   */
  const handleTokenRefresh = (): Observable<string | null> => {
    if (!isRefreshingToken) {
      isRefreshingToken = true;
      tokenSubject.next(null);

      // Call the refresh token API
      return from(authService.refreshToken()).pipe(
        tap((response: any) => {
          if (response?.data?.token?.access_token) {
            authService.saveUserCredentials(localStorage, response.data, false);
            tokenSubject.next(response.data.token.access_token);
          } else {
            alertService.alert(Alert.ERROR, "Session expired");
            authService.signOut();
            router.navigate(["auth"]);
          }
        }),
        catchError((refreshError) => {
          isRefreshingToken = false;
          tokenSubject.next(null);
          alertService.alert(
            Alert.ERROR,
            refreshError.message || "Session expired"
          );
          authService.signOut();
          router.navigate(["auth"]);
          return throwError(() => new Error("Error refreshing token"));
        }),
        tap(() => {
          isRefreshingToken = false;
        })
      );
    } else {
      return tokenSubject.pipe(
        filter((token) => token != null),
        take(1)
      );
    }
  };

  /**
   * Checks if the token needs to be refreshed based on the time elapsed since its creation.
   * If needed, initiates the refresh token process.
   * @returns Observable<boolean> - Returns true if the token was refreshed, false otherwise.
   */
  const checkAndRefreshToken = (): Observable<boolean> => {
    const userData = JSON.parse(localStorage.getItem("userData") as string);

    if (!userData || !userData.token) return of(false);

    const { created_at, expires_in } = userData.token;
    const rememberMe = localStorage.getItem("remember_me") === "true";

    const currentTime = Math.floor(Date.now() / 1000);
    const timeElapsed = currentTime - created_at;
    const halfExpirationTime = expires_in / 2;

    if (rememberMe && timeElapsed > halfExpirationTime) {
      return handleTokenRefresh().pipe(
        switchMap((newToken) => of(newToken ? true : false)),
        catchError(() => of(false))
      );
    }

    return of(false);
  };

  /**
   * Handles HTTP errors that occur during the request.
   * Logs the user out and navigates them to appropriate pages if necessary.
   * @param error HttpErrorResponse - The error to handle.
   * @param req HttpRequest<any> - The request that caused the error.
   * @param next HttpHandlerFn - The handler to forward the request.
   * @returns Observable<HttpEvent<any>> - Throws an error or emits the handled response.
   */
  const handleError = (
    error: HttpErrorResponse,
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
  ): Observable<HttpEvent<any>> => {
    switch (error.status) {
      case 400:
        alertService.alert(Alert.ERROR, error.error.message || "Bad request");
        return throwError(() => error);
      case 401:
        alertService.alert(
          Alert.ERROR,
          error.error.message || "Session expired"
        );
        authService.clearUserCredentials();
        router.navigate(["auth"]);
        return throwError(
          () => new Error("Unauthorized - Token not refreshed")
        );
      case 403:
        router.navigate(["access-denied"]);
        return throwError(() => error);
      case 422:
        if(!req.url.endsWith("api/unique/check")){
          alertService.alert(Alert.ERROR, error.error.message || "Bad request");
        }
        return throwError(() => error);
      case 404:
        alertService.alert(
          Alert.ERROR,
          error.message || error.error.message || "Not found"
        );
        router.navigate(["/not-found"]);
        return throwError(() => error);
      case 500:
        alertService.alert(
          Alert.ERROR,
          error.message || error.error.message || "Internal Server error"
        );
        break;
      default:
        alertService.alert(
          Alert.ERROR,
          error.message || error.error.message || "Unknown error"
        );
    }
    return throwError(() => error);
  };

  if (req.url.includes(`${environment.authApi}refresh-token`)) {
    const token = authService.token;
    if (token) req = addTokenToHeader(req, token.access_token);

    return next(req).pipe(
      catchError((error: HttpErrorResponse) => handleError(error, req, next))
    );
  }

  if (!excludedLinks.some((url) => req.url.includes(url))) {
    const token = authService.token;

    if (token) {
      return checkAndRefreshToken().pipe(
        switchMap((tokenRefreshed) => {
          const finalToken = authService.token || token;
          req = addTokenToHeader(req, finalToken.access_token);

          return next(req).pipe(
            catchError((error: HttpErrorResponse) =>
              handleError(error, req, next)
            )
          );
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
    }
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => handleError(error, req, next))
  );
};
