import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { CrewService } from '../services/crew.service';

@Injectable()
export class EventSelectedGuard {
  constructor(private _Router: Router, private _CrewService: CrewService) {}

  canActivate(): Observable<boolean> {
    if (this._CrewService.activeEvent) return of(true);
    this._Router.navigate(['/crew']);
    return of(false);
  }
}
