import { Routes } from '@angular/router';
import AuthRoutes from '@auth/routes';
import SettingsRoutes from './settings/routes';
import PlanRoutes from '@plan/routes';
import { countriesResolver } from '@shared/resolvers/countries.resolver';
import EventsRoutes from './events/routes';
import { currenciesResolver } from '@shared/resolvers/currencies.resolver';
import CrewRoutes from './crew/routes';

export default [
  {
    path: '',
    resolve: { countries: countriesResolver, currencies: currenciesResolver },
    children: [
      ...AuthRoutes,
      ...PlanRoutes,
      ...SettingsRoutes,
      ...EventsRoutes,
      ...CrewRoutes,
      { path: '**', redirectTo: 'cross-road' },
    ],
  },
  { path: '**', redirectTo: 'cross-road' },
] as Routes;
