import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EVENT_STATUS } from '@plan/events/enums/event-status';
import { SharedEventResponse } from '@plan/events/types/event';
import { map, Observable, of, tap } from 'rxjs';
import { EventDetails } from '@plan/events/types/event';
import { Response } from '@shared/types/generic-response.type';
import { Crew, CrewProfileResponse } from '@shared/types/user.type';
import { environment } from 'src/environments/environment';

@Injectable()
export class CrewService {
  private _ActiveEvent!: EventDetails | null;

  constructor(private _http: HttpClient) {}

  /**
   * Get current crew user assigned event list
   * @param eventStatus - Event status
   * @param currentPage - Page number
   * @param noOfItemsPerPage - Page size
   * @returns - Assigned event list
   */
  public getAssignedEventList(
    eventStatus: EVENT_STATUS = EVENT_STATUS.LIVE,
    currentPage: number = 1,
    noOfItemsPerPage: number = 6
  ): Observable<SharedEventResponse> {
    return this._http.get<SharedEventResponse>(
      `${environment.crewApi}events?status=${eventStatus}&page=${currentPage}&per_page=${noOfItemsPerPage}`
    );
  }

  get activeEvent(): EventDetails | null {
    this._ActiveEvent =
      this._ActiveEvent ||
      JSON.parse(localStorage.getItem('crew-active-event') || 'null');
    return this._ActiveEvent;
  }

  set activeEvent(event: EventDetails) {
    this._ActiveEvent = event;
    localStorage.setItem('crew-active-event', JSON.stringify(event));
  }

  /**
   * @description Fetch event details
   * @param string eventId
   * @returns Observable<EventDetails>
   */
  private _fetchEvent(eventId: string): Observable<EventDetails> {
    return this._http
      .get<Response<{ event: EventDetails }>>(
        `${environment.crewApi}events/${eventId}`
      )
      .pipe(
        map((res) => res.data.event),
        tap((event) => (this.activeEvent = event))
      );
  }

  /**
   * @description Get event details from cache or fetch from server
   * @param string eventId
   * @returns Observable<EventDetails>
   */
  public getEvent(eventId: string): Observable<EventDetails> {
    return this.activeEvent?.id === +eventId
      ? of(this.activeEvent)
      : this._fetchEvent(eventId);
  }
  
  /**
   * @description Get current crew user profile data
   * @returns - crew profile data
   */
  public getCrewProfile(): Observable<Crew> {
    return this._http
      .get<CrewProfileResponse>(`${environment.rootApi}crews/me`)
      .pipe(map((res) => res.data.crew));
  }
}
